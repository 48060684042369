import React from 'react';

import {
  HashRouter,
  // Switch,
  Route,
  // Redirect,
  Navigate,
  // useMatch,
  useParams,
  useLocation,
  Routes
} from "react-router-dom";

import {
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  getTheme,
} from '@fluentui/react'

import ReactLoading from 'react-loading';

import { StoreProvider, useStoreState, useStoreDispatch } from './common/storeContext'

import FormRenderers from './forms';
import DetailListRenderers from './lists';

import LoginForm from './forms/LoginForm'

import AppHeader from './components/AppHeader'
import AppSidebar from './components/AppSidebar'

import { AUTHENTICATION } from './common/actions'

const theme = getTheme();

const App = () => {
  return (
    <StoreProvider>
      <HashRouter forceRefresh={false} >
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/:moduleId" element={<Content />} />
          <Route path="/:moduleId/:id" element={<Content />} />
          <Route path="/login" element={<LoginForm />} />
        </Routes>
      </HashRouter>
    </StoreProvider>
  );
}

const EmptyContentRender = () => {
  return (<></>)
}
/*
const EmptyContentRender = () => {
  // const [mutate] = useMutation(MUTATION_UPLOAD);
  const elementRef = useRef()
  const { mutation } = useMiddletier()

  function onChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    console.log(validity, file);

    if (validity.valid) {
      elementRef.current.type = ''
      elementRef.current.type = 'file'
      // mutate({ variables: { file, bucket: 'temp' } }).then(() =>{
      //   elementRef.current.value = ''
      // })
    }
  }

  const onExport = () => {
    mutation([
      {
        index: '0',
        method: 'exportData',
        params: [
          {
            code: 'module',
            value: 'user',
            graphqlType: 'String',
            required: true
          },
          {
            code: 'filter',
            value: {},
            graphqlType: 'JSON',
            required: true
          },
          {
            code: 'type',
            value: 'csv',
            graphqlType: 'String',
            required: false
          }
        ],
        attributes: []
      }
    ]).then(({ data }) => {
      var dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(data['exportData0']);
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "export.csv");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    })
  }

  return (
    <>
      <input type="file" required onChange={onChange} />
      <input ref={elementRef} type="file" accept=".csv, .json" required onChange={onChange} />
      <PrimaryButton onClick={onExport} text="Export" />
    </>
  );
}
*/
const renderLoader = () => <ReactLoading type={'Cubes'} height={667} width={375} />;

const dialogStyles = { main: { maxWidth: 450 } };

const modalProps = {
  titleAriaId: '',
  subtitleAriaId: '',
  isBlocking: false,
  styles: dialogStyles,
  dragOptions: false,
}

const unauthenticatedDialogContentProps = {
  type: DialogType.normal,
  title: 'Unauthenticated',
  closeButtonAriaLabel: 'Close',
  subText: 'You have to login to access this record.',
}

const offlineDialogContentProps = {
  type: DialogType.normal,
  title: 'Offline',
  //closeButtonAriaLabel: 'Close',
  subText: `You're offline. Check your connection.`,
}


const Content = () => {
  const appState = useStoreState()
  const dispatch = useStoreDispatch()

  const location = useLocation()
  let { id, moduleId } = useParams()
  let options

  try {
    options = location.search && new URLSearchParams(location.search).get("options") ? JSON.parse(new URLSearchParams(location.search).get("options")) : {}
  }
  catch (e) {
    options = {}
  }

  const customRender = options.customRender ?? true
  const onRedirectToLogin = () => {
    dispatch({ type: AUTHENTICATION.LOGOUT, payload: {} })
  }

  var ContentRender = EmptyContentRender

  const key = moduleId
  moduleId = options.module ?? moduleId

  if (id) {
    ContentRender = FormRenderers[moduleId] && customRender === true ? FormRenderers[moduleId] : FormRenderers['default']
  }
  else if (moduleId) {
    ContentRender = DetailListRenderers[moduleId] && customRender === true ? DetailListRenderers[moduleId] : DetailListRenderers['default']
  }

  return (
    <React.Fragment>
      {appState && appState.loggedIn
        ?
        <>
          <Dialog
            hidden={appState.hideErrorDialog}
            onDismiss={onRedirectToLogin}
            dialogContentProps={unauthenticatedDialogContentProps}
            modalProps={modalProps}
          >
            <DialogFooter>
              <PrimaryButton onClick={onRedirectToLogin} text="Ok" />
            </DialogFooter>
          </Dialog>
          <Dialog
            hidden={appState.online}
            //onDismiss={onRedirectToLogin}
            dialogContentProps={offlineDialogContentProps}
            modalProps={modalProps}
          >
            <DialogFooter>
              {/* <PrimaryButton onClick={onRedirectToLogin} text="Ok" /> */}
            </DialogFooter>
          </Dialog>
          <div style={{ position: 'relative', height: '100%', width: '100%', backgroundColor: theme.palette.neutralLighterAlt }}>
            <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
              <AppHeader user={appState.user} />
              <div style={{ position: 'relative', flex: 1 }}>
                <div style={{ zIndex: 100, position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                  <AppSidebar selectedKey={key} />
                  <div id="content-container" style={{ position: 'relative', flex: 1 }}>
                    <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
                      <React.Suspense fallback={renderLoader()}>
                        <ContentRender key={key} moduleId={moduleId} id={id} options={options} />
                      </React.Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        : <Navigate
          state={{ from: location.pathname, search: location.search }}
          to="/login"
        />
      }
    </React.Fragment>
  );
}

export default App;
